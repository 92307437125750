html.color-theme-in-transition,
html.color-theme-in-transition *,
html.color-theme-in-transition *:before,
html.color-theme-in-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0ms !important;
}

html {
  --primary-theme: #e52863;
  --primary-bg: #ffffff;
  /* --primary-text: #000000; */
  --primary-text: rgba(0, 0, 0, 0.9);
  --secondary-text: #7b7b7b;
  --primary-selection: rgba(162, 162, 162, 0.1);
  --primary-shadow: rgba(204, 204, 204, 0.7);
  --accent-color: #ffc500;
  --primary-border: #efefef;
  --primary-footer: #e6e6e6;
  --primary-card: #ffffff;
}

html[data-theme="dark"] {
  --primary-theme: #e52863;
  --primary-bg: #1a1a1a;
  --primary-text: rgba(255, 255, 255, 0.8);
  --secondary-text: rgba(255, 255, 255, 0.6);
  --primary-selection: rgba(0, 0, 0, 0.18);
  --primary-shadow: rgba(8, 8, 8, 0.7);
  --accent-color: #ffc500;
  --primary-border: #292929;
  --primary-footer: #202020;
  --primary-card: #3d3d3d;
}
