.app_short_url_section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.card_small {
  max-width: 300px;
  width: 100%;

  margin: auto;
  height: 100%;
  background-color: var(--primary-card) !important;
}

.card_small .card_small__container .card_small__action_container {
  height: inherit !important;
}

/* Application Title */

.app_title__wrapper {
  gap: 16px 0;
}

/* Application Title User */
.app_title__link_icon {
  color: var(--primary-theme);
}

.app_title__user {
  /* margin-bottom: 24px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .app_title__user {
  }
}
