.auth_dialog .MuiPaper-root {
  color: var(--primary-text) !important;
  background-color: var(--primary-card) !important;
  min-width: 40%;
}

.auth_dialog__button {
  color: var(--primary-theme) !important;
  width: fit-content;
}

.auth_dialog__button__icon {
  padding: 8px;
}
