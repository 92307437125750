.footer {
  background-color: #1e1e1e;
  background-color: var(--primary-footer);
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  border-radius: 6px;
  padding: 24px 24px;
}

.footer .footer__right {
  font-size: small;
  text-align: end;
  color: #9c9c9c;
  color: var(--secondary-text);
}

.footer .footer__name {
  color: var(--primary-text);
}

.footer .footer__copyright > a {
  text-decoration: none;
  color: var(--primary-theme);
}
