.app_box {
  flex-grow: 1;
  width: 100% !important;
}

.tags {
  width: inherit !important;
}

.tags__item {
  color: var(--primary-text) !important;
}

.tags__item.active {
  color: var(--primary-theme) !important;
  font-weight: 600;
}
