.main_container__content {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  justify-items: flex-start;
  min-height: 100vh;
}

.main_container {
  background-color: var(--primary-bg);
  color: var(--primary-text);
}

.main_container__content__item {
  padding-bottom: 32px;
  width: inherit;
}

.app_header_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main_app_container {
  flex-grow: 1;
  display: flex;
}
