/* Main App Container */
.app_container {
  height: 100%;
}

/* Application Title */
.app_title {
  margin-bottom: 24px !important;
  padding: 16px;
}

@media (min-width: 1024px) {
  .app_title {
    width: 80%;
    align-self: center;
    margin-top: 64px !important;
  }
}

.app_title__head {
  font-size: x-large !important;
  color: var(--primary-text);
  margin-bottom: 16px !important;
}

.app_title__head :first-child {
  margin-right: 16px;
  font-size: 40px;
}

.app_title__wa_icon {
  color: #25d366;
}

.app_title__desc {
  color: var(--secondary-text);
  font-size: medium !important;
}

/* Main Content Item */
.app_content {
  align-items: stretch !important;
}

.app_content__item_container {
  flex-grow: 1 !important;
  display: flex;
}

.app_content__item {
  flex-grow: 1 !important;
  display: flex;
  align-items: center !important;
  padding: 0 16px;
}

/* Text Input Control */
.app_content__input_container {
  color: var(--secondary-text) !important;
  margin-right: 8px !important;
}

.app_content__input_container .Mui-focused {
  color: var(--primary-theme) !important;
}

.app_content__input_container .MuiFormLabel-root {
  color: var(--secondary-text);
}

.app_content__input_container .MuiOutlinedInput .Mui-focused {
  color: var(--primary-theme) !important;
}

.app_content__input_section {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 16px !important;
}

/* Selector Input */
.app_content__selector {
  height: 100%;
  margin-right: 8px !important;
}

.app_content__selector_container {
  text-align: right;
  margin-bottom: 16px !important;
}

.app_content__selector .MuiFormLabel-root {
  color: var(--secondary-text) !important;
  top: -5px;
}

.app_content__selector .MuiInputBase-root {
  color: var(--primary-text) !important;
  height: 100%;
}

.app_content__selector .MuiInput-underline:before {
  border-color: var(--secondary-text) !important;
}

.app_content__selector .MuiInput-underline:after {
  border-color: var(--primary-theme) !important;
}

.app_content__selector .MuiSvgIcon-root {
  color: var(--primary-text) !important;
}

/* Text Input Field */
.app_content__input {
  width: 100%;
  color: var(--primary-text) !important;
}

.app_content__input .MuiOutlinedInput-notchedOutline {
  border-color: var(--secondary-text) !important;
}

.app_content__input:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-text) !important;
}

.app_content__input .MuiOutlinedInput-input {
  color: var(--primary-text);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-theme) !important;
}

.MuiTypography-colorTextSecondary {
  color: var(--secondary-text) !important;
}

/* Submit Button */
.app_content__button__item {
  justify-content: flex-start !important;
}

.app_content__button {
  font-size: 40px !important;
  color: var(--primary-theme);
}

.padding_item {
  height: 20%;
}
