.card {
  max-width: 300px;
  width: 100%;
  min-height: 250px;
  margin: auto;
  height: 100%;
  background-color: var(--primary-card) !important;
}

.card__media {
  height: 140px;
}

.card__container {
  height: inherit !important;
}

.card__content .card__content__title {
  color: var(--primary-text) !important;
}

.card__content .card__content__desc {
  color: var(--secondary-text) !important;
}
