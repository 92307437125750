.header__container {
  margin-top: 32px;
}

.toolbar {
}

.appbar {
  background-color: var(--primary-theme) !important;
  border-radius: 6px;
}

.appbar__home {
  margin-right: 16px !important;
}
